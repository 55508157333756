import {
  Advisor,
  AppointmentSubTypeEnum,
  AppointmentTypeEnum,
  LoanAmountEnum,
  MeetingMethodEnum,
  ProficiencyEnum,
  AppointmentOriginEnum,
  AllAdvisorsOption
} from '@atbdigitalteam/obs-shared-components'
import { sortAdvisorList } from '../utils/sortAdvisorList'
import { ApiRequest } from './ApiRequest'
import { QueryParams } from './QueryParams'

const API_SERVER = process.env.API_SERVER || ''
const BASE_PATH = `${API_SERVER}/api/calendars`

export const getAdvisorList = (
  transit: string,
  appointmentType: AppointmentTypeEnum,
  proficiencyLevel: ProficiencyEnum,
  serviceSubType: AppointmentSubTypeEnum,
  meetingMethod: MeetingMethodEnum,
  bookingId?: string,
  loanAmount?: LoanAmountEnum,
  origin?: AppointmentOriginEnum,
  bizOnly?: boolean
): Promise<Advisor[]> => {
  const branchTransit = /^[0-9]{3}$/.exec(transit)
  if (branchTransit === null) {
    throw Error('Transit cannot be null')
  }
  const queryParams = new QueryParams({
    meetingMethod,
    appointmentType,
    proficiency: proficiencyLevel,
    serviceSubType
  })

  if (bookingId) queryParams.append({ bookingId })
  if (loanAmount) queryParams.append({ loanAmount })
  if (origin) queryParams.append({ origin })
  if (bizOnly) queryParams.append({ bizOnly })

  return new ApiRequest()
    .get(`${BASE_PATH}/${branchTransit[0]}/advisors?${queryParams.toString()}`)
    .send()
    .then(response => {
      return [AllAdvisorsOption].concat(sortAdvisorList(response.data))
    })
}

export const getAdvisorByBranch = (transit: string, advisor: string): Promise<Advisor[]> => {
  const branchTransit = /^[0-9]{3}$/.exec(transit)
  if (branchTransit === null) {
    throw Error('Transit cannot be null')
  }

  const queryParams = new QueryParams({
    advisor
  })

  return new ApiRequest()
    .get(`${BASE_PATH}/${branchTransit[0]}/advisor?${queryParams.toString()}`)
    .send()
    .then(response => response.data)
}
