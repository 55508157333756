import {
  ActionButton,
  Advisor,
  AdvisorSelectionField,
  AllAdvisorsOption,
  copy,
  DatePickerField,
  DialogueDisplay,
  i18n,
  MeetingMethodEnum,
  minutesToUserFacingTimeString,
  ServiceSubTypes,
  TimeSlot,
  TimeSlotPickerField
} from '@atbdigitalteam/obs-shared-components'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { navigate, RouteComponentProps } from '@reach/router'
import { Form, Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import moment from 'moment-timezone'
import React, { useEffect, useRef, useState } from 'react'

import { UIState } from '../../../../src/styles'
import {
  clearAllStores,
  fetchAvailability,
  fetchAvailableDates,
  notificationService,
  releaseTimeslot,
  useAuthentication,
  useBookingStore,
  useBranchStore,
  useCalendarStore
} from '../../../injectables'
import { getAdvisorList, holdTimeslot } from '../../../requests'
import { routes } from '../../../routing/routes'
import { StringReplace } from '../../../translations'
import { MixpanelEvents } from '../../../translations/mixpanelEvents'
import { ALBERTA_TIMEZONE, FORM_WIDTH } from '../../../utils/constants'
import { required } from '../../../utils/fieldValidation'
import {
  convertBoolean,
  convertClientType,
  currentPage,
  currentWorkflowPage,
  dayOfWeekSet,
  getWorkflow,
  getWorkflowTeam,
  secondsSinceTime,
  timeOfDaySet,
  timeRange
} from '../../../utils/mixpanel'
import { ExpiredPageModal } from '../../components/ExpiredPageModal'
import { Feedback } from '../../components/Feedback'
import { PageTitle } from '../../components/PageTitle'
import { FindATimeFormValues } from '../../../types'
import { AppointmentInfoCard } from '../../components/AppointmentInfoCard'
import { Logout } from '../../components/Logout'
import { mixpanelTrack } from '../../../utils/mixpanelWrapper'
import { ShowAllAdvisorsCheckbox } from '../../components/ShowAllAdvisorsCheckbox'

const useStyles = makeStyles({
  formRoot: {
    width: '100%',
    maxWidth: FORM_WIDTH,
    paddingRight: '8px',
    paddingLeft: '8px',
    marginBottom: '80px'
  },
  formItem: {
    width: '100%',
    marginBottom: '8px'
  },
  formItemNoMargin: {
    width: '100%'
  },
  buttonRow: {
    marginTop: '32px'
  },
  backButton: {
    width: '85px'
  },
  cancelButton: {
    marginLeft: '8px'
  },
  continueButton: {
    width: '119px',
    marginLeft: 'auto'
  },
  inlineLink: {
    textDecoration: 'underline',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#0072F0',
    cursor: 'pointer'
  }
})

export const BaseFindATimePage = (_props: RouteComponentProps) => {
  const startTime = useRef<Date>(new Date())
  const [advisors, setAdvisors] = useState<Advisor[]>([AllAdvisorsOption])
  const classes = useStyles()
  const { dayOfWeek, timeOfDay } = useBranchStore()
  const { isAuthenticated, logout } = useAuthentication()

  const {
    advisor,
    appointmentLength,
    appointmentType,
    bookingId,
    branch,
    date,
    eventId,
    loanAmount,
    meetingMethodCategory,
    meetingMethod,
    minDate,
    proficiency,
    shortDateString,
    serviceSubType,
    timeSlot,
    origin,
    setAdvisor,
    setBookingId,
    setDate,
    setEventId,
    setTimeSlot,
    setBranch,
    timezoneLabel,
    toggleShowAllAdvisors,
    showAllAdvisors,
    businessCheckboxEnabled,
    bizOnlyValue
  } = useBookingStore()

  const { availableSlots, availableDates, isFetching, setAvailableDates, setAvailableSlots } = useCalendarStore()

  const [openExpiredPageModal, setOpenExpiredPageModal] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    if (eventId) {
      setAvailableDates(undefined)
      setAvailableSlots(undefined)
      releaseTimeslot().finally(() => {
        fetchAvailableDates()
        fetchAvailability()
      })
    }
  }, [])

  useEffect(() => {
    if (branch && meetingMethod && appointmentType && proficiency !== undefined && serviceSubType) {
      getAdvisorList(
        branch.transit,
        appointmentType,
        proficiency,
        serviceSubType,
        meetingMethod,
        bookingId,
        loanAmount,
        origin,
        bizOnlyValue
      ).then(advisorList => {
        if (advisor && !advisorList.map(a => a.email).includes(advisor.email)) {
          setAdvisor(undefined)
        }
        setAdvisors(advisorList)
      })
    }
    // tslint:disable-next-line: react-hooks/exhaustive-deps
  }, [meetingMethodCategory, showAllAdvisors])

  useEffect(() => {
    if (!eventId) {
      fetchAvailableDates()
    }
  }, [advisor, meetingMethodCategory, showAllAdvisors])

  useEffect(() => {
    if (!eventId) {
      fetchAvailability()
    }
  }, [advisor, meetingMethodCategory, date, showAllAdvisors])

  useEffect(() => {
    if (!serviceSubType) {
      mixpanelTrack(MixpanelEvents.ExpiredModalDisplayed, {
        page: currentPage(),
        workflowPage: currentWorkflowPage(),
        workflow: getWorkflow(origin),
        workflowTeam: getWorkflowTeam(origin)
      })
      setOpenExpiredPageModal(true)
    } else {
      mixpanelTrack(MixpanelEvents.FindATimePageLand, { pageLanded: 'Yes', page: currentPage() })
    }
  }, [])

  const handleBookAnotherAppointment = () => {
    mixpanelTrack(MixpanelEvents.ExpiredModalButtonClicked, {
      page: currentPage(),
      workflowPage: currentWorkflowPage(),
      workflow: getWorkflow(origin),
      workflowTeam: getWorkflowTeam(origin)
    })
    clearAllStores()
    navigate('/book')
  }

  const handleBack = () => {
    mixpanelTrack(MixpanelEvents.FindATimePageBack, {
      branchLocation: branch?.name,
      clientType: convertClientType(appointmentType),
      appointmentSubtype: serviceSubType,
      appointmentMethod: meetingMethod,
      teamMemberSelection: convertBoolean(advisor),
      appointmentDate: moment.tz(date, ALBERTA_TIMEZONE).format('dddd'),
      appointmentTime: timeSlot ? timeRange(timeSlot.time!, timeSlot.type!) : 'not selected',
      lengthOfAppointment: ServiceSubTypes[serviceSubType!]?.meetingLength[appointmentType!],
      availableDatesCount: availableDates?.length,
      availableSlotsCount: availableSlots?.length,
      pageTime: secondsSinceTime(startTime.current),
      page: currentPage(),
      workflowPage: currentWorkflowPage()
    })
    navigate(-1)
  }

  const handleServiceSubTypeChange = () => {
    mixpanelTrack(MixpanelEvents.FindATimePageSubtypeChange, {
      pageTime: secondsSinceTime(startTime.current),
      page: currentPage(),
      workflowPage: currentWorkflowPage()
    })
    navigate('/book')
  }

  const handleMeetingMethodChange = () => {
    mixpanelTrack(MixpanelEvents.FindATimePageApptMethodChange, {
      pageTime: secondsSinceTime(startTime.current),
      page: currentPage(),
      workflowPage: currentWorkflowPage()
    })
    navigate('/book')
  }

  const handleBranchChange = () => {
    mixpanelTrack(MixpanelEvents.FindATimePageBranchChange, {
      pageTime: secondsSinceTime(startTime.current),
      page: currentPage(),
      workflowPage: currentWorkflowPage()
    })
    navigate(-1)
  }

  const handleNoAppointmentsClick = () => {
    setBranch(null)
    navigate(-1)
  }

  const handleShowAllAdvisorsSlots = () => {
    if (!isFetching) {
      toggleShowAllAdvisors()
    }
  }

  const handleSubmit = (values: FindATimeFormValues, actions: FormikHelpers<FindATimeFormValues>): void => {
    holdTimeslot(
      branch?.transit!,
      date!,
      timeSlot?.time!,
      timeSlot?.type!,
      appointmentType!,
      serviceSubType!,
      proficiency!,
      meetingMethod,
      undefined,
      advisor?.email
    )
      .then(response => {
        actions.setSubmitting(false)
        mixpanelTrack(MixpanelEvents.FindATimePageNext, {
          branchLocation: branch?.name,
          clientType: convertClientType(appointmentType),
          appointmentSubtype: serviceSubType,
          appointmentMethod: meetingMethod,
          teamMemberSelection: convertBoolean(advisor),
          appointmentDate: moment.tz(date, ALBERTA_TIMEZONE).format('dddd'),
          appointmentTime: timeRange(timeSlot?.time!, timeSlot?.type!),
          lengthOfAppointment: ServiceSubTypes[serviceSubType!]?.meetingLength[appointmentType!],
          pageTime: secondsSinceTime(startTime.current),
          page: currentPage(),
          workflowPage: currentWorkflowPage(),
          timeFilter: timeOfDaySet(timeOfDay),
          dayFilter: dayOfWeekSet(dayOfWeek)
        })
        if (response.status === 201) {
          setBookingId(response.data.bookingId)
          setEventId(response.data.eventId)
          setAdvisor(response.data.advisor)
          navigate(routes.summary.path)
        } else if (response.status === 204) {
          fetchAvailability()
          actions.setSubmitting(false)
          notificationService.createNotification(
            'Cannot proceed. Looks like someone has just taken your selected time slot',
            UIState.Info
          )
        }
      })
      .catch(() => {
        notificationService.createNotification('Something went wrong', UIState.Error)
        actions.setSubmitting(false)
      })
  }

  return (
    <Formik
      initialValues={
        {
          advisor,
          date,
          timeSlot
        } as FindATimeFormValues
      }
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <Form>
          <Feedback />
          <Grid
            data-testid='find-a-location-form'
            container
            justifyContent='center'
            alignItems='center'
            direction='column'
          >
            <Grid container direction='column' spacing={2} className={classes.formRoot}>
              <Grid item className={classes.formItemNoMargin}>
                <PageTitle
                  littleTitle={i18n.__(copy.Book.LittleTitle)}
                  bigTitle={i18n.__(copy.Book.FindTimeBigTitle)}
                  step={i18n.__(copy.Book.StepLabels.FindTime)}
                />
              </Grid>
              <Grid item className={classes.formItemNoMargin}>
                <AppointmentInfoCard
                  serviceSubType={serviceSubType}
                  serviceSubTypeLabel='Type'
                  onChangeServiceSubTypeClick={handleServiceSubTypeChange}
                  meetingMethod={meetingMethod}
                  meetingMethodLabel='Method'
                  onChangeMeetingMethodClick={handleMeetingMethodChange}
                  branch={branch}
                  branchLabel='Location'
                  onChangeBranchClick={handleBranchChange}
                />
              </Grid>
              <Grid item className={classes.formItem}>
                <AdvisorSelectionField
                  name='advisor'
                  options={advisors}
                  onChange={(event: React.ChangeEvent<{ value: string }>) => {
                    advisors.forEach(teamMember => {
                      if (teamMember.email === event.target.value) {
                        setAdvisor(teamMember)
                      }
                    })
                  }}
                />
              </Grid>

              {availableDates?.length === 0 && !isFetching && (
                <Grid item className={classes.formItem}>
                  <DialogueDisplay
                    id='no-dates'
                    displayMessage={
                      <>
                        {i18n.__(copy.Book.NoAppointmentsMessage.BeforeLink)}
                        <Link
                          className={classes.inlineLink}
                          data-testid='no-dates-link'
                          onClick={() => handleNoAppointmentsClick()}
                        >
                          {i18n.__(copy.Book.NoAppointmentsMessage.LinkText)}
                        </Link>
                        {i18n.__(copy.Book.NoAppointmentsMessage.AfterLink)}
                      </>
                    }
                  />
                </Grid>
              )}
              {availableDates && availableDates.length > 0 && (
                <>
                  <Grid item className={classes.formItem}>
                    <DatePickerField
                      name='date'
                      minDate={minDate}
                      availableDates={availableDates}
                      onChange={(value: Date | null) => {
                        setDate(value)
                      }}
                      validate={value => required(value, i18n.__(copy.Validation.required.date))}
                      validateOnBlur
                    />
                  </Grid>
                  {businessCheckboxEnabled && (
                    <Grid item className={classes.formItemNoMargin} style={{ paddingTop: 0, marginTop: '-10px' }}>
                      <ShowAllAdvisorsCheckbox
                        checked={showAllAdvisors == true}
                        onChange={handleShowAllAdvisorsSlots}
                      />
                    </Grid>
                  )}

                  {availableSlots?.length === 0 && !isFetching && (
                    <Grid item className={classes.formItem}>
                      <DialogueDisplay
                        id='no-slots'
                        displayMessage={
                          <>
                            {StringReplace(i18n.__(copy.NoAvailableSlotsMessage.BeforeLink), {
                              '%DATE%': shortDateString
                            })}
                            <Link href={i18n.__(copy.NoAvailableSlotsMessage.Link.Url)}>
                              {i18n.__(copy.NoAvailableSlotsMessage.Link.Text)}
                            </Link>
                            {i18n.__(copy.NoAvailableSlotsMessage.AfterLink)}
                          </>
                        }
                      />
                    </Grid>
                  )}
                  {availableSlots && availableSlots.length > 0 && (
                    <Grid item className={classes.formItem}>
                      <TimeSlotPickerField
                        name='timeSlot'
                        timeslots={availableSlots}
                        timezoneLabel={timezoneLabel}
                        onChange={(value: TimeSlot) => {
                          setTimeSlot(value)
                        }}
                        validate={value => required(value, i18n.__(copy.Validation.required.timeSlot))}
                        validateOnBlur
                      />
                    </Grid>
                  )}
                </>
              )}
              {isFetching && (
                <Grid item className={classes.formItem}>
                  <CircularProgress style={{ marginLeft: '45%' }} />
                </Grid>
              )}

              <Grid item className={classes.formItem}>
                <Typography variant='body2' data-testid='appointment-length-notice'>
                  {StringReplace(i18n.__(copy.AppointmentLengthLabel[meetingMethod || MeetingMethodEnum.ONLINE]), {
                    '%LENGTH%': minutesToUserFacingTimeString(appointmentLength)
                  })}
                </Typography>
              </Grid>

              <Grid item className={classes.formItem}>
                <Grid container direction='row' className={classes.buttonRow}>
                  <Grid item className={classes.backButton}>
                    <ActionButton
                      testid='back-button'
                      buttonText={i18n.__(copy.Buttons.Back)}
                      type='button'
                      variant='outlined'
                      onClick={handleBack}
                    />
                  </Grid>
                  <Grid item className={classes.cancelButton}>
                    <Logout
                      testid='cancel-button'
                      loggedIn={isAuthenticated()}
                      text={i18n.__(copy.Buttons.CancelShort)}
                      onClick={() => logout()}
                    />
                  </Grid>
                  {(!availableDates || availableDates.length > 0) && (!availableSlots || availableSlots.length > 0) && (
                    <Grid item className={classes.continueButton}>
                      <ActionButton
                        buttonText={i18n.__(copy.Buttons.Continue)}
                        submitting={isSubmitting}
                        disabled={isSubmitting || !date || !timeSlot || !availableSlots || !availableDates}
                        type='submit'
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <ExpiredPageModal
                data-testid='expired-page-modal'
                modalBody={i18n.__(copy.Modals.ExpiredPage)}
                open={openExpiredPageModal}
                displayCloseButton={false}
                modalButtonText='Booking page'
                onModalButtonClick={() => handleBookAnotherAppointment()}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export const FindATimePage = observer(BaseFindATimePage)
