import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import React from 'react'
export interface ShowAllAdvisorsCheckboxProps {
  checked: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
}

const useStyles = makeStyles({
  root: {
    marginRight: '10px',
    '&$disabled': {
      cursor: 'not-allowed'
    }
  },
  checkBox: {
    color: '#75757B',
    '&.Mui-checked': {
      color: '#0072F0'
    }
  },
  disabled: {}
})

export const ShowAllAdvisorsCheckbox = ({ checked, onChange, disabled }: ShowAllAdvisorsCheckboxProps) => {
  const classes = useStyles()

  const handleCheckboxClick = () => {
    onChange(!checked)
  }

  return (
    <Grid container direction='row' alignItems='center'>
      <Grid item>
        <FormControlLabel
          classes={{
            root: classes.root,
            disabled: classes.disabled
          }}
          disabled={disabled}
          control={
            <Checkbox
              classes={{
                root: classes.checkBox
              }}
              onChange={handleCheckboxClick}
              checked={checked}
              data-testid='show-all-advisors-checkbox'
              aria-disabled={undefined}
            />
          }
          label='Show all advisors (not only business advisors)'
        />
      </Grid>
    </Grid>
  )
}
